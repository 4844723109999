import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { Link } from "gatsby"
import { Gallery } from '../components/gallery'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Sidebar from "../components/sidebar"

const shortcodes = { Gallery }

export default function PageTemplate({ data: { mdx } }) {

  let promoSlika = mdx.frontmatter.promoslika.childImageSharp.fluid
  
  return (
    <div>
    <Layout>
    <SEO title={mdx.frontmatter.title} />
    <main id="main">
    <Img fluid={promoSlika} className="alignwide"/>
    <h5 className="objava-datum">{mdx.frontmatter.datum} </h5>
    <h1 className="objava-naslov">{mdx.frontmatter.title}</h1>
    <MDXProvider components={shortcodes}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      </main>
     <Sidebar /> 
  </Layout>
  </div>
  )
}

// export default function Stranica({mdx}) {
//   return (
//     <Layout>
//     <SEO title="Home" />
//     <h1 className="naslov-objave">{mdx.frontmatter.title}</h1>
//     <MDXProvider components={shortcodes}>
//         {/* <MDXRenderer>{mdx.body}</MDXRenderer> */}

//       </MDXProvider>
      
//   </Layout>
//   )
// }

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        title
        datum(formatString: "DD. MM. YYYY.")
        promoslika {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`




